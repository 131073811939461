.about {

  color: #222;

  &__hero {
    margin: 1rem 0;
    font-size: 2.4rem;
    display: inline-block;

    & > strong {
      border-bottom: 2px solid;
    }
  }

  &__paragraph {
    margin-bottom: 2rem;
    border-radius: 6px;
    padding: 0rem 0 0rem 0;
    margin-top: 1rem;
    font-size: 1.4rem;
    & > strong {
      color: #1f1f1f;
    }
  }

  &__link {
    color: map-get($colors, "autobulb-green");
    color: #888;
    padding-bottom: 0.1rem;
    text-decoration: none;
    border-bottom: 0.1rem solid #009bff;
  }

  &__button {
    width: 100%;
    height: 100%;
    text-align: left;
    cursor: pointer;
    color: #6a6a6a;
    font-size: 1rem;
    //filter: drop-shadow(0px 1px 4px #666);
    background: white;
    border-radius: 6px;
    padding: 0.2rem 0rem;

    &:hover, &:active {

      .back-btn__icon {
        color: #aaa;
        left: -.2rem;
      }
    }

  }

  &__icon {
    position: relative;
    color: #ccc;

  }

  &__text-wrap {

    @include respond-to(hover-device) {
      max-width: 75rem;
    }
  }

  &__text {
    font-size: 1.2rem;
    position: relative;
    top: 0.1rem;
  }
  &__map-header {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
  }

}