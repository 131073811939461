.internal-links {

    &__wrap {
        @extend %custom-border-top;
        padding: 0 1.2rem;

    }

    &__list {
        display: flex;

    }

    &__item {
        display: flex;

        &:first-child {

        }

        &:nth-last-child(1) {
            flex-grow: 1;

            & > a {
                width: 100%;

                & > h4 {
                    text-align: right;
                }
            }
        }

        //&:nth-last-child(2) {
        //    //flex-grow: 1;
        //}

        &:last-child {
        }

        button {
            cursor: pointer;
        }
    }

    &__cta {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #aaa;

        &:hover {
            color: #333333;

        }

        h4 {

        }

        svg {
            font-size: 1rem;
            margin-left: 0.4rem;
        }

    }
}