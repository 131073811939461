.checkbox {

  &__wrap {
    padding: 0 0;
    text-align: right;

    & > label {
      cursor: pointer;

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-to(hover-device) {
        display: inline-block;
        width: auto;
      }
    }
  }

  &__list {
  }

  &__item {
    cursor: pointer;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1.6rem;

      @include respond-to(hover-device) {
          margin-right: 2.6rem;
      }
    }

    input + span {
      margin-left: 0.8rem;
    }
  }
}

input[type="checkbox"]{

  display: none;

  + span {
    display: inline-block;
    font-size: .5rem;
    border: 1px solid #eee;
    padding: 0.2rem;
    color: #888;
    border-radius: 4px;


    //@include respond-to(hover-device) {
    //  font-size: .8rem;
    //}

    & > svg {
      transition: all 0.1s ease-out;
    }
  }
  &:checked {
    + span > svg {
      opacity: 1;
    }
  }
  &:not(checked) {
    + span > svg {
      opacity: 0.2;
    }
  }
}