
.main-filters {

    position: absolute;
    top: 0;
    height: 7rem;
    width: 100%;
    padding-right: $menu_gap;
    transition: height 0.1s ease;
    background-color: white;
    z-index: map-get($z-indexes, "filter-expansion");

    @include respond-to(hover-device) {
        height: 3rem;
        border-bottom: 1px solid #ddd;
        padding-right: 0;
    }

    &__wrap {
        height: inherit;
    }

    &__list {
        display: flex;
        height: inherit;
        width: 100%;
        top: 0;
        left: 0;
        @extend %site-wide-padding;

        .header--minified & {

            @include respond-to(hover-device) {
            }
        }


    }

    &__item {
        @extend .inline-list-item-margin;
        color: #ccc;
        font-size: 1.2rem;

        @media (max-width: map-get($b-points, "mob")) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1.4rem;
            margin-right: 1.2rem;
        }

        &:last-child {
            margin-left: auto;
            margin-right: 0;
        }

        & span {

            @include respond-to(hover-device) {
                //padding-bottom: 1.3rem;

                .header--minified & {
                    padding-bottom: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

        }

        &--on {
        text-decoration: underline;

            .main-filters--expanded & {
                color: #555;
            }
        }

        &--hamburger {
            margin-left: auto;
        }

        &--expansion-toggle button{
            font-size: .75rem;
            transform: rotate(-90deg);
        }

        &--expanded button{
            transform: rotate(90deg);
        }
    }
}

.filter-expansion {
    display: flex;
    align-items: center;
    position: absolute;

    &__logo {

    }

    &__wrap {
        background-color: white;
        position: absolute;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;
        z-index: map-get($z-indexes, "filter-expansion-wrap");
        padding: 2rem 2rem 0 2rem;
        padding-right: $menu_gap  + 2rem;
        @extend %viewport-wide;
        transition: top 0.4s ease-out;
        top: 7rem;
        height: calc(100vh - 7rem);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-to(hover-device) {

            top: 4rem;
            height: auto;
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            padding-right: 12rem;
            padding-left: 12rem;

            &--close {
                top: -40rem;

            }

            &--open {
                top: 3rem;
            }
        }
    }

    &__list {
        //display: flex;
    }

    &__item {
        @extend .inline-list-item-margin
    }

    &__close-button, &__clear-all-button {
        font-size: 0.6rem;
        cursor: pointer;

        span {
            font-size: 0.6rem;
            margin-left: .6rem;

            svg {
                vertical-align: bottom;
            }
        }
    }

    &__close-button {

        width: calc(100% + 4rem);
        background-color: white;
        border-top: 1px solid #ccc;
        height: 3rem;
        margin-left: -2rem;
        text-align: center;

        @include respond-to(hover-device) {
            width: 100vw;
            left: 0;
            margin-left: -12rem;
        }
    }

    &__clear-all-button {
        padding-bottom: 1rem;
        text-align: left;
    }
}

.funnel-button {

    font-size: 1rem;

    .header--minified & {
        height: 100%;
    }

    &--closed {
        color: #ccc;
    }

    &--open {
        color: #aaa;
    }
}

.nav-slide {
    position: absolute;
    top: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;
    height: 100vh;
    width: 100%;
    transition: right 0.2s ease-out;
    right: calc(-100% - 20rem);
    z-index: map-get($z-indexes, "nav-slide");

    @include respond-to(hover-device) {
        position: relative;
        box-shadow: none;
        right: 0;
        height: auto;
        z-index: map-get($z-indexes, "nav-slide-desktop");
        &--open {
            right: 0;
        }
}

    &--open {
        right: calc(0% - #{$menu_gap});
    }

    &--close {
        //right: calc(-100%);

    }
}