.track {

    //border-radius: 10px;
    @extend %brand-border-radius;
    overflow: hidden;
    font-family: Verdana, Geneva, sans-serif;

    .grid-view & {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @extend %custom-border;
    }

    &__body {

        margin-bottom: auto;

        .grid-view & {
            padding: 1.8rem 1.2rem 0 1.2rem;
        }
    }

    &-items {
        .grid-view & {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 3.4rem 2.2rem;

            @include respond-to(hover-device) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (min-width: map-get($b-points, "tab")) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: map-get($b-points, "des")) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .list-view & {
            display: flex;
            flex-direction: column;
        }

        &--animated {
            animation-name: example;
            animation-duration: .5s;
            animation-timing-function: ease-out;
        }
    }

    .list-view & {
        display: block;
        margin-bottom: 4.4rem;
        border-radius: 0px;
        padding-bottom: 2rem;
        border-bottom: 1px solid #666;

    }

    &__image-wrap {
        flex-basis: 10rem;
        flex-shrink: 0;
        height: 10rem;
        padding: 0;
        background-color: transparent;
        filter: drop-shadow(0px 5px 3px #55555544);
        border-radius: 30rem;
        overflow: hidden;
        position: relative;

        .split-hori & {

        }
    }

    &__text-wrap {
        flex-grow: 1;
        padding-right: 2.2rem;
    }

    &__image {

        width: 100%;
        height: 100%;
        //filter: grayscale(1); // blur disfunctioning because of this
        transition: filter 0.05s linear !important;

        & + img {
            @extend %full-size-absolute;
            transition: all 1s ease-out;
        }
    }

    &__default-img-layer {

        background-color: #161616;
        color: white;
        &.show {
            opacity: 1;
        }

        &.hide {
            opacity: 0;
        }
    }


    @include respond-to(hover-device) {

        &:hover {
            .track__image {
                filter: grayscale(0);
            }
        }
    }
    &__track-title, &__artist-name, &__album-title {
        font-size: 1.2rem;
        display: block;
    }
    &__track-title {
        font-weight: 400;
    }

    &__artist-name {
        font-weight: 700;
    }

    &__album-title {
        font-weight: 400;
        color: #bbbbbb;

    }


    &__header {
        display: flex;
        margin-bottom: 0.8rem;
    }
}

.img {

    &--loading {
        filter: blur(3rem);
    }

    &--loaded {
        filter: blur(0);
    }

    &__wrap {
        overflow: hidden;
    }
}