%custom-border {
    border: $border-style;
}

%custom-border-top {
    border-top: $border-style;
}

%custom-border-bottom {
    border-bottom: $border-style;
}

%flex-center-children {
    display: flex;
    justify-content: center;
    align-items: center;
}

%outer-pad {

    padding: 0 2rem;

    @media (min-width: map-get($b-points, "tab")) {
        padding: 0 4rem;
    }

    @media (min-width: map-get($b-points, "des")) {
        padding: 0 8rem;
    }
}

.inline-list-item-margin {

    margin-right: 0.4rem;

    @media (min-width: map-get($b-points, "tab")) {
        margin-right: 1.4rem;
    }

    @media (min-width: map-get($b-points, "des")) {
        margin-right: 1.8rem;
    }
}

%viewport-wide {
    width: 100vw;
}

%full-size-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

%brand-border-radius {
    border-radius: 10px;
    @media (min-width: map-get($b-points, "tab")) {
        border-radius: 8px;
    }

    @media (min-width: map-get($b-points, "des")) {
        border-radius: 6px;
    }
}

%site-wide-padding {
    padding: 0 2rem;

    @include respond-to(hover-device) {
        padding-right: map-get($main-padding, "mob");
        padding-left: map-get($main-padding, "mob");
    }

    @media (min-width: map-get($b-points, "tab")) {
        padding-right: map-get($main-padding, "tab");
        padding-left: map-get($main-padding, "tab");
    }

    @media (min-width: map-get($b-points, "des")) {
        padding-right: map-get($main-padding, "des");
        padding-left: map-get($main-padding, "des");
    }
}
%one-liner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}