.modal-box {

    color: #444;
    padding: .8rem;

    &__wrap {
        position: fixed;
        top: 1rem;
        width: 100%;
        z-index: map-get($z-indexes, "modal-box");
    }

    &__list {
        display: flex;
        justify-content: center;
    }

    &__item {
        display: flex;
        position: absolute;
        background-color: #eee;
        align-items: center;
        padding: 0rem 1.4rem;
        border: 1px solid #ddd;
        width: calc(100vw - 8rem);

        @include respond-to(hover-device) {
            width: 40rem;
        }

        pointer-events: auto;
        transition: top 0.3s ease-out;
        height: 4rem;
        color: #666;
        @extend %brand-border-radius;

        & > .icon-logo {
            font-size: 1em;
            margin-right: .8rem;
        }

        & > .close-btn {
            font-size: 0.6rem;
            margin-left: auto;
            cursor: pointer;
            color: #666;
        }
        & > .message-icon {
            font-size: 0.8rem;
            margin-right: 1rem;
        }
    }
}