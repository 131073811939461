.links {

    &__wrap {
        display: block;
        @extend %custom-border-top;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;


        main.expanded-item & {
            background-color: transparent;
        }

        .list-view & {
            background-color: transparent;
            padding: 0;
        }

        @media (min-width: map-get($b-points, "tab")) {
            //padding: 0 3rem;
        }

        @media (min-width: map-get($b-points, "des")) {
            //padding: 0 5rem;
        }
    }

    &__link {

        font-size: .8rem;
        cursor: pointer;
        padding: 0.6rem 0.5rem;
        transition: color 0.15s ease-in-out;

        & > a, span {

            color: #777;

            .list-view & {
                color: #777;
            }

            main.expanded-item & {
                color: #777;
            }
        }


        &--active{
            //@media(hover: hover) and (pointer: fine) and (min-width: map-get($b-points, "mob")) {
            //    &:hover {
            //        transition: color 0s ease-in-out;
            //
            //        color: #777;
            //    }
            //}
        }
        &--inactive{
            opacity: 0.2;
            cursor: auto;
        }

        &--expand, &--back{
            flex-grow: 1;
            text-align: right;
        }

        & > .href {

            &--expand, &--go-back {
                //color: inherit;
                text-decoration: inherit;
                text-transform: uppercase;
                font-weight: bold;
                cursor: pointer;
                text-shadow: 1px 3px 6px #55555544;

                .list-view & {
                    text-align: right;
                }
            }
        }
    }
}

//.track:hover {
//
//
//    .links__link--expand{
//        color: #888;
//
//    }
//}