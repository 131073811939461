.concerts {


    &__wrap {
        background-color: #efefef;
        @extend %brand-border-radius;

    }

    &__list {
        margin: 2rem 0 2rem;

    }



    &__text {
        margin-right: 1rem;
    }

    &__link {
        color: #444;
        text-decoration: none;
        padding: 1rem;
        width: 100%;
    }

    &__icon {
        color: #888;
        font-size: 1rem;
    }
    &__title {
        font-size: 1.4rem;
        margin-bottom: 0;
        color: #444;
    }

    &__item {
        font-size: 1.2rem;
        cursor: pointer;
        color: white;
        transition: color 0.15s ease-in-out;
        display: flex;

        &--active {
            @include respond-to(hover-device) {
                &:hover {
                    transition: color 0s ease-in-out;

                    color: #777;
                }
            }
        }

        &--inactive {
            opacity: 0.2;
            cursor: auto;
        }
    }
}