.expanded-concert {


    &__title {
        margin-bottom: 1rem;
    }

    &__location {
        margin-bottom: 2rem;
    }

    &__date {
        font-size: 1.2rem;
        cursor: pointer;
        color: #333;
        transition: color 0.15s ease-in-out;
    }

    &__list {
        font-size: 1.2rem;
        cursor: pointer;
        transition: color 0.15s ease-in-out;
    }

    &__item {
        font-size: 1.2rem;
        cursor: pointer;
        transition: color 0.15s ease-in-out;
        border-bottom: 1px solid #eee;
        margin-bottom: 0rem;

        &:hover, &:active {
            border-color: #ccc;
        }
    }

    &__link {
        text-decoration: none;
        display: block;
        padding: 2rem 0;
    }

}

.concert-tracks {
    display: grid;
    grid-template-columns: .5fr 1fr 12rem;
    color: #444;
    font-size: 1.2rem;
    grid-gap: 2rem;

    &__row {

    }

    &__cta {
        text-align: right;


    }

    &__artist {
        //text-align: right;
    }

    &__title {
        //text-align: right;
    }

    &__duration {
        text-align: right;
    }
}