.main-nav {
    display: flex;
    align-items: center;
    position: absolute;
    height: 7rem;
    width: 100%;
    background-color: white;
    z-index: map-get($z-indexes, "main-nav");
    @extend %site-wide-padding;

    .header--minified & {
        height: 4rem;
    }

    @include respond-to(hover-device) {
        position: relative;
        height: 8rem;
        .header--minified & {
            height: 8rem;
        }
    }

    &__wrap {
        flex-grow: 1;
    }

    &__logo {
        font-size: 2rem;
        filter: drop-shadow(2px 4px 3px black);
        transition: transform 0.1s ease;
        margin-right: 2.6rem;
        @include respond-to(hover-device) {
            margin-right: 3rem;
        }

        .header--minified & {
            transform: scale(.5);
            transition: transform 0.1s ease-out;
        }
    }

    &__list {
        display: flex;
        flex-grow: 1;
        margin-right: auto;
    }

    &__item {
        a {
            color: #ccc;
            text-decoration: none;
            margin-right: 2rem;
            font-size: 1.2rem;
            font-weight: 400;
        }

        & .active {
            color: #888;
        }
    }

    &__hamburger {
        @include respond-to(hover-device) {
            display: none;
        }
    }


}