@import 'variables.scss';
@import 'media-queries.scss';
@import 'fonts.scss';
@import 'reset.scss';
@import 'mixins.scss';
@import 'elements.scss';
@import 'generic-modifiers.scss';
@import 'animations.scss';
@import 'blocks/_main-nav.scss';
@import 'blocks/_filter-expansion.scss';
@import 'blocks/filter-index.scss';
@import 'blocks/_track.scss';
@import 'blocks/_tags.scss';
@import 'blocks/_links.scss';
@import 'blocks/_expanded-item.scss';
@import 'blocks/_bkg-holder.scss';
@import 'blocks/_concerts.scss';
@import 'blocks/_modal-box.scss';
@import 'blocks/_expanded-concert.scss';
@import 'blocks/_splash.scss';
@import 'blocks/_main-search.scss';
@import 'blocks/_switch-button.scss';
@import 'blocks/_welcome-message.scss';
@import 'blocks/_quick-slide.scss';
@import 'blocks/_player.scss';
@import 'blocks/_share-box.scss';
@import 'blocks/_footer.scss';
@import 'blocks/_darken-layer.scss';
@import 'blocks/_checkbox.scss';
@import 'blocks/_back-button.scss';
@import 'blocks/_more-info-button.scss';
@import 'blocks/_internal-links.scss';
@import 'blocks/_external-links.scss';
@import 'blocks/_about.scss';
@import 'blocks/_google-map.scss';

* {
    touch-action: manipulation;
}

body {
    background-color: map-get($colors, "body-bkg");
    //font-family: 'Open Sans', sans-serif;
    font-family: Verdana, Geneva, sans-serif;

}

// h1 - h6
h1 {
    font-size: map-deep-get($Hs, "h1", "mob");

    @media (min-width: map-get($b-points, "tab")) {
        font-size: map-deep-get($Hs, "h1", "tab");
    }

    @media (min-width: map-get($b-points, "des")) {
        font-size: map-deep-get($Hs, "h1", "des");
    }
}

h2 {
    font-size: map-deep-get($Hs, "h2", "mob");

    @media (min-width: map-get($b-points, "tab")) {
        font-size: map-deep-get($Hs, "h2", "tab");
    }

    @media (min-width: map-get($b-points, "des")) {
        font-size: map-deep-get($Hs, "h2", "des");
    }
}

h3 {
    font-size: map-deep-get($Hs, "h3", "mob");

    @media (min-width: map-get($b-points, "tab")) {
        font-size: map-deep-get($Hs, "h3", "tab");
    }

    @media (min-width: map-get($b-points, "des")) {
        font-size: map-deep-get($Hs, "h3", "des");
    }
}

.icon-logo {
    //font-size: 2em;
    color: #ccc;
    transition: filter 0.85s ease-out;
}

svg {
    width: 1em;
    fill: currentColor;
    font-size: 2em;
    text-align: center;
    vertical-align: middle;
    box-sizing: content-box;
}