.splash {
  background-color: #eee;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    font-size: 7.5rem;
  }

  & > .splash-icon {
    & > svg {
      font-size: 12rem;

      @include respond-to(hover-device) {
        font-size: 10rem;
      }
    }
  }
}

.animate-opacity {
  animation-name: animate-opacity-back-and-forth;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.animate-size {
  animation-name: animate-size-back-and-forth;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
