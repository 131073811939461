



.footer {
    @extend %site-wide-padding;
    position: relative;
    width: 100%;
    z-index: map-get($z-indexes, "footer");
    border-top: 1px solid #eee;
    display: flex;
    background-color: white;

    &__preloader {

        .footer__animative-indicator {
            width: 5px;
            height: 5px;
            display: none;
        }

        &--animate-fetch {

            animation-name: footerFetchAnimation;
            animation-timing-function: ease-out;
            animation-duration: .75s;
            animation-iteration-count: infinite;

            .footer__animative-indicator {
                background-color: green;
                position: relative;
                border-radius: 5px;
                overflow: hidden;
            }
        }

    }

    & > nav {
        width: inherit;
    }
}

.footer-nav {
    &__wrap{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: inherit;
    }

    &__list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: $footer-height;
        width: inherit;
    }

    &__item{

        margin: .2rem 0;

        a, span {
            color: #888;
            text-decoration: none;
        }
    }
}

.footer{
    margin-top: $footer-height;
}

.footer,
.push {
    height: $footer-height;
}