$z-indexes: (
        "iframe": 1,
        "footer": 3,
        "quick-slide-wrap": 4,
        "quick-slide-button": 5,
        "nav-slide-desktop": 6,
        //"player": 7,
        "something": 10,
        "filter-expansion-wrap": 15,
        "filter-expansion": 20,
        "popup": 30,
        "main-nav": 40,
        "nav-slide": 50,
        "header": 100,
        "player": 101,
        "modal-box": 102,
);

$colors: (
        "body-bkg": white,
        "grid-item-bkg": #f8f8f8,
        "grey_bright": #eee,
        // switch
        "label-color": #ccc,
        "switch-background": #777,
        "switch-finger": white,
        "grey_mid": #aaa,
        "grey_dark": #888,
        "rephlex_green": #138a43,
        "tag-highlight": #138a43,
        "autobulb-green": #23543C,
        "autobulb-red": #5A2619,
        "autobulb-black": #0E0500,
);

$menu_gap: 8rem;
$border-style: 1px solid #eee;
$footer-height: 16rem;


$brand-: (
        "mob": 768px,
        "tab": 1020px,
        "des": 1360px
);



$greys: (
        "grey_0": darken( #fff, 0% ),
        "grey_1": darken( #fff, 10% ),
        "grey_2": darken( #fff, 20% ),
        "grey_3": darken( #fff, 30% ),
        "grey_4": darken( #fff, 40% ),
        "grey_5": darken( #fff, 50% ),
        "grey_6": darken( #fff, 60% ),
        "grey_7": darken( #fff, 70% ),
        "grey_8": darken( #fff, 80% ),
        "grey_9": darken( #fff, 90% ),
        "grey_10": darken( #fff, 100% ),
);


// example deep map
$Hs: (
        "h1": (
                "mob":  1.4rem,
                "tab":  4rem,
                "des":  5.4rem
        ),
        "h2": (
                "mob":  1.5rem,
                "tab":  1.6rem,
                "des":  1.8rem
        ),
        "h3": (
                "mob":  1.2rem,
                "tab":  1.3rem,
                "des":  1.4rem
        ),

);

h2 {
        color:  #333
}

$main-padding: (
        "mob": 4rem,
        "tab": 8rem,
        "des": 12rem
);