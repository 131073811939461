ul {
    list-style: none;
}

section.manage-items {
    @extend %outer-pad;
}

button {
    padding: 0;
}

main {
    top: 7rem;
    position: relative;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: -1 * $footer-height;
    min-height: calc(100vh - #{$footer-height});
    @extend %site-wide-padding;

    #root & {
        padding-top: 2rem;
    }

    &:not(.editorial){
        @include respond-to(hover-device) {
            top: 11rem;
            max-width: 148rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.home, &.editorial, &.expanded-concert, &.about {
        @include respond-to(hover-device) {
            padding-top: 2rem;
        }
    }




}

strong {
    font-weight: 700;
}

label, button {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background-color: map-get($greys, "grey_1");
    z-index: map-get($z-indexes, "header");
    transform : translate3d(0, 0, 0);
    box-shadow: 1px 6px 7px #aaaaaa11;
    transition: transform 0.1s ease-out;

    &--minified {
        height: 4rem;

        @include respond-to(hover-device) {
            transform: translateY(-8rem);
        }

    }

    &--expanded {
    }

}