.back-btn {

  &__wrap {
    margin-bottom: 2rem;
    border-radius: 6px;
    padding: 0rem 0 0rem 0;
    margin-top: 1rem;
  }

  &__button {
    width: 100%;
    height: 100%;
    text-align: left;
    cursor: pointer;
    color: #6a6a6a;
    font-size: 1rem;
    //filter: drop-shadow(0px 1px 4px #666);
    background: white;
    border-radius: 6px;
    padding: 0.2rem 0rem;

    &:hover, &:active {

      .back-btn__icon {
        color: #aaa;
        left: -.2rem;
      }
    }

  }

  &__icon {
    position: relative;
    color: #ccc;

  }

  &__text {
    font-size: 1.2rem;
    position: relative;
    top: 0.1rem;
  }

}