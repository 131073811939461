.quick-slide {
    &__wrap {
        position: relative;
        min-width: 100%;
        height: auto;
        overflow-x: hidden;
        margin-bottom: 4.6rem;
        z-index: map-get($z-indexes, "quick-slide-wrap");
        width: calc(100% + (2 * #{(map-get($main-padding, "mob"))} - (.5 * #{(map-get($main-padding, "mob"))})));
        margin-left: -1 * map-get($main-padding, "mob");
        padding-left: map-get($main-padding, "mob");;

        @include respond-to(hover-device) {
            width: calc(100% + (2 * #{(map-get($main-padding, "tab"))} ));
            margin-left: -1 * map-get($main-padding, "tab");
            padding-left: map-get($main-padding, "tab");;

        }

        @media (min-width: map-get($b-points, "tab")) {
            width: calc(100% + (2 * #{(map-get($main-padding, "des"))} ));
            margin-left: -1 * map-get($main-padding, "des");
            padding-left: map-get($main-padding, "des");;
        }

    }

    &__content {
        will-change: transform;
        transition: transform 0.2s ease-out;
    }

    &__list {
        width: fit-content;
        //outline: 4px dashed red;
        flex-wrap: nowrap;
        display: flex;

        & > li {
            max-width: 300px;
            min-width: 300px;
            display: inline-block;
            margin-right: 2rem;
        }
    }

    &__button {
        position: absolute;
        width: 50px;
        height: 50px;
        z-index: map-get($z-indexes, "quick-slide-button");
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        transition: opacity 0.2s ease-out;
        background-color: white;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
        line-height: 25px;
        font-size: 16px;
        color: red;

        &--left {

            left: 6rem;

            @include respond-to(hover-device) {
                left: 6rem;
            }

            @media (min-width: map-get($b-points, "tab")) {
                left: 6rem;
            }
        }

        &--right {

            right: 6rem;

            @include respond-to(hover-device) {
                right: 6rem;
            }

            @media (min-width: map-get($b-points, "tab")) {
                right: 6rem;
            }
        }

        &--active {
            opacity: 1;

        }

        &--disabled {
            opacity: 0
        }
    }

    &__icon {

        color: #999;
        font-size: .85rem;
        pointer-events: none;

        &--left {
            //left: 0;
        }

        &--right {
            //right: 0;
        }
    }

    &__title{

        margin-bottom: 1rem;

        &__text{
            font-size: 2rem;
            @include respond-to(hover-device) {
                font-size: 3rem;
            }

            @media (min-width: map-get($b-points, "tab")) {
                font-size: 4rem;
            }
        }
    }
}
