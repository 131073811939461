.player {
    position: fixed;
    bottom: 0;
    height: 20rem;
    width: 100%;
    display: flex;
    justify-content: center;

    &__wrap {
        pointer-events: none;
        z-index: map-get($z-indexes, "player");
        position: fixed;
    }


    & > .div {

        position: ABSOLUTE;
        right: 0;
        bottom: 0;
    }

    .iframe {
        pointer-events: all;
        bottom: 3rem;
        position: absolute;
        right: 0.5rem;
        z-index: map-get($z-indexes, "iframe");
        border: 1rem solid #202020;
        border-radius: 1rem 1rem 0 0;
        border-bottom-width: 0;
        border-left-width: 0;
        border-right-width: 0;

        &--youtube{

        }

        &--spotify{

        }
    }


    &__close, &__expand {
        pointer-events: all;
        background-color: #202020;
        right: 0.5rem;
        position: absolute;
        width: 30rem;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        color: white;
    }

    &__close {
        top: 0.5rem;
        border-radius: 1rem 1rem 0 0;
        height: 2rem;
        padding: 0 1.6rem;
        cursor: pointer;
    }

    &__expand {
        bottom: 0.5rem;
        height: 3rem;
        border-radius: 0 0 1rem 1rem;
        padding: 0 2rem;
    }

    &__close-btn {
        top: 0.5rem;
        border-radius: 1rem 1rem 0 0;
        color: #aaa;
        font-size: .65rem;
        cursor: pointer;

        & > span {
            text-align: right;
        }
    }
}